const employment = [
  {title: "Idea Lab Kids", 
  position: "STEM Summer Camp Instructor", 
  location: "Oakville, Ontario, Canada", 
  date: "June 2019​ - August 2019​", 
  desc: `Worked with children ages 4-14 educating them on different aspects 
  of engineering and design such as programming, web development and robotics.`},

  {title: "Toronto District School Board", 
  position: "Robotics Advisor", 
  location: "Toronto, Ontario, Canada", 
  date: "July 2018 - August 2018", 
  desc: `Summer intern responsible for hands-on guidance and training of students 
  from grades SK to 8 in the field of robotics. Provided advice to teachers with 
  respect to program content best suited to the various age groups. Assisted students 
  in the use of robotics tools and peripherals as well as with block coding applications.`},

  {title: "Sqrrl", 
  position: "Summer Intern", 
  location: "Cambridge, Massachusetts, USA", 
  date: "June 2017 - August 2017", 
  desc: `Duties included successful setup and implementation of network security monitor. 
  Completed the basics of ethical hacking and the creation of malicious payloads. Worked 
  with enterprise-level virtualization environments.`},
  
  {title: "Self-Employed", 
  position: "Freelance Programming", 
  location: "Toronto, Ontario, Canada", 
  date: "November 2014 - March 2015", 
  desc: `Developed and sold scripts online at the request of many communities running game servers.`},
];

const education = [
  {title: "Queen's University", 
  position: "Bachelor of Applied Science - BASc Computer Engineering Innovation Stream 2022", 
  location: "", 
  date: "September 2018 - Present", 
  desc: ``},

  {title: "Academy for Gifted Children - P.A.C.E.", 
  position: "High School Diploma - Ontario Scholar 2018", 
  location: "", 
  date: "September 2014 - June 2018", 
  desc: ``},
];

const experience = [
  {title: "", 
  position: "C, Java, Python, R, BASIC, BASH, VHDL, MATLAB, JavaScript, React/React Native", 
  location: "", 
  date: "", 
  desc: ``},
];

const awards = [
  {title: "Ranked in the top 1% of teams · VEX Robotics World Championship", 
  position: "", 
  location: "Louisville, Kentucky, USA", 
  date: "April 2018", 
  desc: ``},

  {title: "1st Place Team, Tournament Champions", 
  position: "VEX Robotics Regionals", 
  location: "Sault Ste. Marie, Ontario, Canada", 
  date: "December 2015", 
  desc: `Programming Skills Champion Award, Robot Skills Champion Award, Amaze Award`},
];

const interests = [ 
  {title: "Queen's Rocket Engineering Team", 
  position: "Web Developer", 
  location: "", 
  date: "September 2019 - Present", 
  desc: `Redesigned entire website from scratch using the React Javascript library. 
  Designed and implemented custom scripts to optimize the importing of data from an external source.`},

  {title: "Robotics", 
  position: "", 
  location: "", 
  date: "September 2011 - June 2018", 
  desc: `Participated in after-school robotics program from grade six to twelve, 
  three to four times per week. Programs included VEX EDR, VEX IQ, Robofest and First 
  Lego League. Participated in several tournaments in Canada and the United States with 
  robots/drones built solely by student team members.`},

  {title: "Playing Instruments", 
  position: "Piano, Guitar, Pan Flute, Baritone, Harmonica", 
  location: "", 
  date: "", 
  desc: ``},

  {title: "Snowboarding", 
  position: "", 
  location: "", 
  date: "", 
  desc: ``},
];

const volunteering = [
  {title: "Blessed Sacrament Parish", 
  position: "Volunteer", 
  location: "Toronto, Ontario, Canada", 
  date: "September 2014 January 2020", 
  desc: `Assisting elderly members of the decorating committee with preparing the Church for 
  holidays and special events.`},

  {title: "Toronto Children's Chorus", 
  position: "Web Developer", 
  location: "Toronto, Ontario, Canada", 
  date: "January 2020 - Present", 
  desc: `Commencing work on updating website.`},
];

export {employment, education, experience, awards, interests, volunteering}