import React from 'react';
import { Parallax } from 'react-parallax';
import './LandingPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function LandingPage(props) {
  return (
    <div className="LandingPage">
        <Parallax bgImage={require("../images/code.png")} blur={3} 
        renderLayer={percentage => (
                <div
                    style={{
                        position: 'absolute',
                        background: `rgba(0, 0, 0, 0)`,
                        left: '45%',
                        top: '45%',
                        width: percentage * 1000,
                        height: percentage * 500,
                    }}
                >
                <h1 className="ParallaxText">Welcome to MichaelPavia.dev</h1>
                </div>
        )}>
             <div style={{ height: '95vh' }} />
        </Parallax>
    </div>
  );
}
