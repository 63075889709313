import React from 'react';
import './ResumePage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {employment, education, experience, awards, interests, volunteering} from './resumeElements.js'

function RElement(props) {
  const items = props.info.map((item) =>
    <React.Fragment>
      <h4>{item.title}</h4>
      {item.location !== "" ? <h5>{item.position} · {item.location}</h5> : <h5>{item.position}</h5>}
      <h6>{item.date}</h6>
      <p>{item.desc}</p>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <h2>{props.title}</h2>
      <hr/>
      <div>{items}</div>
    </React.Fragment>
  )
}

export default function ResumePage(props) {
  return (
    <div className="ResumePage">
        <div className="Main">
          <div className="titleContainer">
            <h1>Michael Pavia</h1>
            <h3>Second Year Computer Engineering Student</h3>
          </div>
          <hr style={{height: "4px"}}/>
          <h2>Summary</h2>
          <hr/>
          <p>I am a second-year computer engineering student with a lifelong passion and keen interest in technology and programming. I have summer experience working for a U.S.-based cybersecurity firm as well as teaching engineering to elementary school students in the summer. I currently work as a web developer for the Queen's Rocket Engineering Team. I have won various awards in robotics and have competed regionally, nationally, and internationally.</p>
          {/* <iframe src="https://resume.creddle.io/embed/7myqixltiic" width="850" height="1100" seamless></iframe> */}
          <RElement title="Employment" info={employment}/>
          <RElement title="Education" info={education}/>
          <RElement title="Programming Experience" info={experience}/>
          <RElement title="Awards" info={awards}/>
          <RElement title="Activities/Interests" info={interests}/>
          <RElement title="Volunteering" info={volunteering}/>
        </div>
    </div>
  );
}
