import React from 'react';
import './ContactPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function ContactPage(props) {
  return (
    <div className="ContactPage">
    </div>
  );
}
